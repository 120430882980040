<template>
	<!-- 分页 -->
	<div class="flex-center mt-20">
		<span>共{{totalNum}}条</span>
		<el-pagination
		  prev-text="上一页"
		  next-text="下一页"
		  background
		  layout="prev, pager, next"
		  :total="totalNum"
		  :currentPage="currentPage"
		  :page-size="pageSize"
		  @current-change="currentChange"
		  >
		</el-pagination>
	</div>
</template>

<script>
	export default {
		props:{
			total:{//总数
				type:Number,
				default:0
			},
			pageSize:{//每页条数
				type:Number,
				default:10
			},
			current:{//页数
				type:Number,
				default:1
			}
		},
		data(){
			return{
				currentPage:1,//页数
				totalNum:0
			}
		},
		watch:{
			 "current": {
				handler(newVal, oldVal) {
					console.log("=======",newVal);
					// newVal?this.currentPage=newVal:this.currentPage=1;
					this.currentChange(newVal)
					// this.currentPage=newVal
				},
				deep:true,
			  },
			  "total": {
				handler(newVal, oldVal) {
					this.totalNum = newVal
				},
				deep:true
			  }
		},
		created() {
			this.totalNum=this.total;
			// this.currentChange(this.current)
			this.currentPage=this.current
		},
		mounted() {
			
			
			// this.currentChange(this.current)
			// this.currentPage=this.current;
		},
		methods:{
			/* 上一页，下一页，改变页数触发 */
			currentChange(e){
				this.currentPage = e;
				this.$emit("change",e)
				this.$forceUpdate()
			}
		}
	}
</script>

<style scoped>
	::v-deep .btn-prev{
		padding: 2px 10px !important;
		border: 1px solid #ddd;
	}
	::v-deep .btn-next{
		padding: 2px 10px !important;
		border: 1px solid #ddd;
	}
	::v-deep .btn-prev>span{
		line-height: 25px;
	}
	::v-deep .btn-next>span{
		line-height: 25px;
	}
</style>
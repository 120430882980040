<template>
	<div class="navbar">
		
		<div class="w-100 h-100 flex-space-between">
			<div class="home-container boxSizing flex-box">
				<div class="home-wrapper">
					<router-link to="/">
						<img src="@/assets/home.png" class="home-icon">
					</router-link>
				</div>
				<p class="username">欢迎您,{{userInfo.nickName?userInfo.nickName:""}}</p>
			</div>
			
			
			<div class="right-menu flex-box">
				<div class="time-container">
					<span class="time-title">{{deteInfo.time}}</span>
					<span class="data-title">{{deteInfo.day}} {{deteInfo.week}}</span>
				</div>
				<div class="message-wrapper mr-20 pointer" @click="messageDialog = !messageDialog;">
					<el-badge :value="msgNum" class="item" :hidden="msgNum==0">
						<img src="@/assets/msg.png" class="user-avatar">
					</el-badge>
				</div>
				<el-dropdown class="avatar-container">
					<div class="avatar-wrapper">
						<img :src="userInfo.avatar?userInfo.avatar:require('@/assets/user.png')" class="user-avatar">
					</div>
					<el-dropdown-menu slot="dropdown" class="user-dropdown">
						<router-link to="/">
							<el-dropdown-item>
								首页
							</el-dropdown-item>
						</router-link>
						<el-dropdown-item>
							<div class="flex-column py-10 boxSizing w-100" @click.stop="isSetting=!isSetting">
								<div class="flex-box w-100">
									<div class="item-title-view">
										<div class="item-text2 pointer" id="set">设置</div>
									</div>
								</div>
								<div v-show="isSetting" class="set_box w-100 mt-10 boxSizing">
									<div class="py-10  boxSizing fontColor-999 pointer borderBottmon"
										@click.stop="showSetPass=true;">修改密码</div>
									<div class="py-10 boxSizing fontColor-999 pointer"
										@click="open=true;userDialog=false;">
										修改头像
									</div>
								</div>
							</div>
						</el-dropdown-item>
						<el-dropdown-item divided @click.native="showMajor=true">
							<span style="display:block;">修改专业</span>
						</el-dropdown-item>
						<el-dropdown-item divided @click.native="showMessage=true">
							<span style="display:block;">客户留言</span>
						</el-dropdown-item>
						<el-dropdown-item divided @click.native="logout">
							<span style="display:block;">退出登录</span>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		

		<div class="message-dialog boxShadow" v-if="messageDialog">
			<div class="message-container">
				<div class="message-title flex-space-between px-30 boxSizing">
					<span>消息
						<span style="font-size: 13px;cursor: pointer;color: #fff;"
							@click="allReadNotice()">全部标记已读</span>
					</span>
					<i class="el-icon-close fontWeight font20 pointer" @click="messageDialog=false"></i>
				</div>
				<el-scrollbar class="item-scrollbar" wrap-class="default-scrollbar__wrap">
					<div class="item-view" v-for="(item,index) in messageList" :key="index"
						@click.stop="onMsgData(item)">
						<div class="item-icon-view">
							<el-image class="msg-icon" :src="require('@/icons/svg/msg.svg')"></el-image>
						</div>
						<div class="item-data">
							<div class="item-msg-title">{{item.title}}</div>
							<div class="item-msg-text mt-15">{{item.message}}</div>
							<div class="del-title mt-15">{{item.createTime}}</div>
						</div>
						<div class="del-view">
							<el-image class="del-icon" v-if="item.status=='1'" @click.stop="delNotice(item)"
								:src="require('@/icons/svg/del.svg')"></el-image>
							<span v-else>未读</span>
						</div>
					</div>
				</el-scrollbar>
			</div>
		</div>
		

		<el-dialog title="修改专业" :close-on-click-modal="false" :visible.sync="showMajor" width="55%">
			<div class="flex-center">
				<treeTransfer :from-data="majorList" :to-data="toList" :default-checked-keys="userUpdateInfo.majorId"
					@addBtn="addTreeTransfer" @removeBtn="removeTreeTransfer" width="700px">
				</treeTransfer>
			</div>


			<span slot="footer" class="dialog-footer">
				<el-button @click="showMajor = false">取 消</el-button>
				<el-button type="primary" @click="confirmCheck">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="修改密码" :close-on-click-modal="false" :visible.sync="showSetPass" width="30%">
			<div>
				<el-form :model="passForm" :rules="rules" ref="passForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="旧密码" prop="oldPassword">
						<el-input v-model="passForm.oldPassword" type="password"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="newPassword">
						<el-input v-model="passForm.newPassword" type="password"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="confirmPassword">
						<el-input v-model="passForm.confirmPassword" type="password"
							@change="equalToPassword"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showSetPass = false">取 消</el-button>
				<el-button type="primary" @click="setPassSubmit">确 定</el-button>
			</span>
		</el-dialog>



		<el-dialog title="客户留言" top="5vh" :visible.sync="showMessage" width="75%" @open="getMessage(1)">
			<div class="w-100">
				<el-table :data="leaveMessage" style="width: 100%" height="450"
					:header-cell-style="{'text-align':'center','background':'#f2f2f2','border':'none'}"
					:cell-style="{'text-align':'center','font-size':'16px','height':'40px'}">
					<el-table-column fixed prop="userName" label="姓名" width="250">
					</el-table-column>
					<el-table-column prop="question" label="留言内容" width="">
						<template slot-scope="scope">
							<div  :title="scope.row.question">
								{{scope.row.question}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="提交时间" width="">
					</el-table-column>
					<el-table-column prop="reply" label="回复内容" width="">
					</el-table-column>
					<el-table-column prop="replyTime" label="回复时间" width="">
					</el-table-column>
					<el-table-column prop="status" label="状态" width="120">
						<template slot-scope="scope">
							<!-- 0提交、1已回复、2已完成 -->
							<span :style="{'color':scope.row.status=='2'?'#00aa7f':''}"
								class=" pointer ml-20">{{scope.row.status=='0'?'已提交':scope.row.status=='1'?'已回复':'已完成'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="zip" label="操作" width="180">
						<template slot-scope="scope">
							<span class="fontColor-index pointer ml-20" @click="reply(scope.row)"
								v-if="scope.row.status == '0'">回复</span>
						</template>
					</el-table-column>
				</el-table>

				<div class="flex-center mt-20">
					<pagination :current="messageParams.pageNum" :total="messageTotal" @change="getMessage">
					</pagination>
				</div>
			</div>
		</el-dialog>

		<el-dialog title="回复留言" :visible.sync="replyShow" width="40%">
			<div class="w-100">
				<el-input type="textarea" :rows="8" placeholder="请填写您要留言的内容" v-model="replyInfo.reply">
				</el-input>

				<div slot="footer" class="dialog-footer mt-20">
					<el-button @click="replyShow = false" style="margin-right: 30px;">取 消</el-button>
					<el-button type="primary" @click="replySumbit">确 定</el-button>
				</div>
			</div>
		</el-dialog>

		<el-dialog :title="title" :visible.sync="open" width="800px" append-to-body @opened="modalOpened"
			@close="closeDialog">
			<el-row>
				<el-col :xs="24" :md="12" :style="{height: '350px'}">
					<vue-cropper ref="cropper" :img="options.img" :info="true" :autoCrop="options.autoCrop"
						:autoCropWidth="options.autoCropWidth" :autoCropHeight="options.autoCropHeight"
						:fixedBox="options.fixedBox" :outputType="options.outputType" @realTime="realTime"
						v-if="visible" />
				</el-col>
				<el-col :xs="24" :md="12" :style="{height: '350px'}">
					<div class="avatar-upload-preview">
						<img :src="previews.url" :style="previews.img" />
					</div>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col :lg="2" :sm="3" :xs="3">
					<el-upload action="#" :http-request="requestUpload" :show-file-list="false"
						:before-upload="beforeUpload">
						<el-button size="small">
							选择
							<i class="el-icon-upload el-icon--right"></i>
						</el-button>
					</el-upload>
				</el-col>
				<el-col :lg="{span: 1, offset: 2}" :sm="2" :xs="2">
					<el-button icon="el-icon-plus" size="small" @click="changeScale(1)"></el-button>
				</el-col>
				<el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
					<el-button icon="el-icon-minus" size="small" @click="changeScale(-1)"></el-button>
				</el-col>
				<el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
					<el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()"></el-button>
				</el-col>
				<el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
					<el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()"></el-button>
				</el-col>
				<el-col :lg="{span: 2, offset: 6}" :sm="2" :xs="2">
					<el-button type="primary" size="small" @click="uploadImg()">提 交</el-button>
				</el-col>
			</el-row>
		</el-dialog>
		<audio style="display: none;" src="@/assets/mp3/duang.mp3"  ref="audioPlayer"></audio>
		
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import {
		removeToken
	} from '@/utils/auth'
	import {
		getUserInfo,
		uploadAvatar,
		updateUserPwd,
		getCurrentUser,
		updateUser,
		treeselect as majorTreeSelect,
		leaveMessageList,
		leaveMessageEdit
	} from "@/api/user"
	import {
		noticeList,
		readAllNotice,
		readNotice,
		removeNotice
	} from "@/api/index"

	import {
		VueCropper
	} from "vue-cropper";
	import {
		debounce
	} from '@/utils'
	/* 树形穿梭框 */
	import treeTransfer from "@/components/treeTransfer/index";
	export default {
		components: {
			VueCropper,
			treeTransfer
		},
		data() {
			return {
				msgNum: 0,
				deteInfo: {
					time: "",
					day: "",
					week: "",
				},
				userName: "",
				time: null, //定时器
				messageDialog: false,
				isMessage: false,
				isSetting: false, //是否显示设置
				showSetPass: false, //显示修改密码弹框
				userInfo: {}, //用户信息
				passForm: {
					oldPassword: "",
					newPassword: "",
					confirmPassword: ""
				},
				rules: {
					oldPassword: [{
						required: true,
						message: '旧密码不能为空',
						trigger: 'blur'
					}],
					newPassword: [{
							required: true,
							message: '新密码不能为空',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 20,
							message: '长度在 6 到 20 个字符',
							trigger: 'blur'
						}
					],
					confirmPassword: [{
						required: true,
						message: '确认密码不能为空',
						trigger: 'blur'
					}],
				},
				// 是否显示cropper
				visible: false,
				// 弹出层标题
				title: "修改头像",
				previews: {},
				resizeHandler: null,
				// 是否显示弹出层
				open: false,
				options: {
					img: require('@/assets/user.png'), // 裁剪图片的地址
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 200, // 默认生成截图框宽度
					autoCropHeight: 200, // 默认生成截图框高度
					fixedBox: true, // 固定截图框大小 不允许改变
					outputType: "png" // 默认生成截图为PNG格式
				},
				majorList: [], //专业
				props: {
					label: 'label',
					children: 'children'
				},
				showMajor: false, //专业显隐弹框
				majorInfo: '', //已选择的专业
				userUpdateInfo: { //修改用户信息
					majorId: [],
					major: [],
					userId: "",
					userName: ""
				},
				userMajor: "", //用户专业
				checkedKeys: [], //默认回显专业

				showMessage: false, //留言显隐
				messageParams: { //参数
					type: 1,
					pageSize: 10,
					pageNum: 1
				},
				total: 0,

				replyShow: false,
				replyInfo: {},
				replyData: {}, //回复参数
				messageTotal: 0,
				leaveMessage: [], //留言列表
				audios: null, //铃声
				filterMethod(query, item) {
					return item.label.indexOf(query) > -1;
				},
				toList: [], //右边专业
				defaultCheckedKeys: [], //默认选中的节点
				showAudio:false,//是否显示播放
				messageList:[]
			}
		},
		watch: {
			"$socketPublic.state.msg": {
				immediate: true,
				deep: true,
				handler(newVal, oldVal) {
					console.log("监听socket的消息-------------", newVal);
					if (newVal && newVal.data) {
						this.getNoticeList(1,false);
						// if (JSON.parse(newVal.data).type == 'message') {
							this.playMessageAudio();
						// }
					}else{
						this.showAudio = false;
					}
				}
			}
		},
		computed: {
			...mapGetters([
				'avatar'
			])
		},
		beforeDestroy() {
			this.time = null;
			clearInterval(this.time);
		},
		mounted() {
			this.showAudio = false;
			this.getTime();
			this.getNoticeList(1,true);
			this.getMajorTreeselect();
			this.getUserInfoFun();
			this.time = setInterval(() => {
				this.getTime()
			}, 1000);
		},
		methods: {
			// 树穿梭框监听事件
			removeTreeTransfer(fromData, toData, obj) {
				this.userUpdateInfo.majorId = []
				this.userUpdateInfo.major = []
				this.getToDataList(toData);
			},
			addTreeTransfer(fromData, toData, obj) {
				this.userUpdateInfo.majorId = []
				this.userUpdateInfo.major = []
				this.getToDataList(toData);
			},
			// 这个是数据处理的函数，将选中的树结构的子id取出
			getToDataList(toData) {
				toData.map((r) => {
					this.userUpdateInfo.majorId.push(r.id);
					this.userUpdateInfo.major.push(r.label);
					if (r.children && r.children.length > 0) {
						this.getToDataList(r.children)
					}
				})
			},
			/* 播放消息提示 */
			playMessageAudio() {
				// this.showAudio = true;
				const audioPlayer = this.$refs.audioPlayer;
				setTimeout(()=> {
					// audioPlayer.currentTime = 0; // 重置播放进度为0
					if (audioPlayer) {
						audioPlayer.load(); // 重新加载音频
						audioPlayer.play()
					}
				}, 500);
			},
			onMsgData(item) {
				item.status = 1;
				readNotice({
					id: item.id,
					status: 1
				}).then(res => {
					this.getNoticeList(1,false);
					if (item.orthType == 'message') {
						this.messageDialog = false;
						this.showMessage = true
					}
				})
			},
			delNotice(item) {
				removeNotice([item.id]).then(res => {
					this.getNoticeList(1,false);
					this.getMessage(1)
					this.$message.success("删除成功");
				})
			},
			allReadNotice() { //标记全部为已读
				readAllNotice().then(res => {
					this.getNoticeList(1,false);
				})
			},
			replySumbit() {
				if(!this.replyInfo.reply){
					return this.$message.error("留言不能为空");
				}
				this.replyInfo.lawyerRead = '1';
				this.replyInfo.status = '1';
				leaveMessageEdit(this.replyInfo).then(() => {
					this.$message.success("已回复");
					this.replyShow = false;
					this.getMessage(1)
				})
			},
			/* 回复信息 */
			reply(item) {
				if (item.status == '1' || item.status == '2') {
					this.$message.success("已完成或已回复");
					return
				}
				this.replyInfo = item;
				this.replyShow = true;
			},
			/* 获取用户留言列表 */
			getMessage(e) {
				if (e) {
					this.messageParams.pageNum = e;
				}
				leaveMessageList(this.messageParams).then((res) => {
					this.leaveMessage = res.rows;
					this.messageTotal = res.total;

				})
			},
			/* 确定专业 */
			confirmCheck() {
				let objs = {
					majorId: JSON.stringify(this.userUpdateInfo.majorId),
					major: this.userUpdateInfo.major.join(','),
					userId: this.userUpdateInfo.userId,
					userName: this.userUpdateInfo.userName

				}
				updateUser(objs).then(res => {
					this.$message.success("修改成功");
					this.getUserInfoFun();
					this.userUpdateInfo.majorId = []
					this.userUpdateInfo.major = []
					this.showMajor = false;
				})
			},
			// 获取专业的接口
			getMajorTreeselect() {
				majorTreeSelect().then(res => {
					this.majorList = res.data
				})
			},
			/* 获取用户信息 */
			getUserInfoFun() {
				getUserInfo({}).then((res) => {
					this.userInfo = res.user;
					this.userUpdateInfo.userId = res.user.userId;
					this.messageParams.lawyerId = res.user.userId;
					this.userUpdateInfo.userName = res.user.userName;
					this.userUpdateInfo.majorId = JSON.parse(res.user.majorId);
					let arr = res.user.major ? res.user.major.split(",") : [];
					let arrId = res.user.majorId ? JSON.parse(res.user.majorId) : [];
					this.userMajor = arr.join("-")
					this.checkedKeys = JSON.parse(res.user.majorId);
					console.log("res.user", res.user);
					
					// this.$store.commit('SET_USERID',res.user.userId);
					// this.$store.commit('SET_NICKNAME',res.user.nickName);
					// console.log("----存储用户信息----",this.$store.state.user)
					this.getMessage();
				});
			},
			/* 获取通知消息 */
			getNoticeList(e,type) {
				this.messageList = []
				noticeList({
					noticeType: 2,
					pageSize: 10,
					pageNum: e ? e : 1
				}).then((res) => {
					this.messageList = res.rows;
					this.msgNum = res.msg ? parseInt(res.msg) : 0;
					if(this.messageList.length>0&&type){
						for (var i = 0; i < this.messageList.length; i++) {
							let item = this.messageList[i];
							if(item.status!=1){
								this.playMessageAudio();
								break
							}
						}
					}
				})
				this.$forceUpdate()
			},
			/* 获取时间 */
			getTime() {
				this.deteInfo.time = this.$funs.getNowTime('hh-mm');
				this.deteInfo.day = this.$funs.getNowTime('mm-dd');
				this.deteInfo.week = this.$funs.getNowTime('week');
			},
			// async logout() {
			// 	this.$router.push(`/login?redirect=${this.$route.fullPath}`)
			// }

			logout() {
				removeToken();
				this.$confirm('确定注销并退出系统吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$socketPublic.dispatch('closeWebSocket');
					removeToken();
					location.href = '/login';
					return
				}).catch(() => {});
			},
			/* 修改密码 */
			setPassSubmit() {
				this.$refs['passForm'].validate((valid) => {
					if (valid) {
						updateUserPwd(this.passForm.oldPassword, this.passForm.newPassword).then(response => {
							this.$message.success("修改成功");
							this.showSetPass = false;
							removeToken();
							for (let key in this.passForm) {
								this.passForm[key] = ""
							}
							this.$socketPublic.dispatch('closeWebSocket');
							removeToken();
							location.href = '/login';
						});
					}
				})

			},
			/* 验证确认密码 */
			equalToPassword(e) {
				console.log("equalToPassword", e)
				if (this.passForm.newPassword !== this.passForm.confirmPassword) {
					this.$message.error('两次输入的密码不一致');
					this.passForm.confirmPassword = ""
				}
			},
			/* 修改头像 */
			uploadSuccess(e) {
				console.log("修改头像", e.file);
				this.userInfo.avatar = e.url;
				/** 上传图片 */
				let formData = new FormData();
				formData.append("avatarfile", e.file);
				console.log(formData.get('avatarfile'));
			},
			// 编辑头像
			editCropper() {
				this.userDialog = false;
				this.open = true;
				this.$forceUpdate()
			},
			// 打开弹出层结束时的回调
			modalOpened() {
				this.visible = true;
				if (!this.resizeHandler) {
					this.resizeHandler = debounce(() => {
						this.refresh()
					}, 100)
				}
				window.addEventListener("resize", this.resizeHandler)
			},
			// 刷新组件
			refresh() {
				this.$refs.cropper.refresh();
			},
			// 覆盖默认的上传行为
			requestUpload() {},
			// 向左旋转
			rotateLeft() {
				this.$refs.cropper.rotateLeft();
			},
			// 向右旋转
			rotateRight() {
				this.$refs.cropper.rotateRight();
			},
			// 图片缩放
			changeScale(num) {
				num = num || 1;
				this.$refs.cropper.changeScale(num);
			},
			// 上传预处理
			beforeUpload(file) {
				if (file.type.indexOf("image/") == -1) {
					this.$message.error('文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。');
				} else {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						this.options.img = reader.result;
					};
				}
			},
			// 上传图片
			uploadImg() {
				this.$refs.cropper.getCropBlob(data => {
					let formData = new FormData();
					console.log("修改头像data---", data)
					formData.append("avatarfile", data);
					uploadAvatar(formData).then(response => {
						this.open = false;
						this.options.img = response.imgUrl;
						this.userInfo.avatar = response.imgUrl;
						this.$message.success("修改成功");
						this.visible = false;
					});
				});
			},
			// 实时预览
			realTime(data) {
				this.previews = data;
			},
			// 关闭窗口
			closeDialog() {
				this.options.img = this.$store.getters.avatar
				this.visible = false;
				window.removeEventListener("resize", this.resizeHandler)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item-view {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 20px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ccc;
		cursor: pointer;
	}

	.item-icon-view {
		width: 68px;
		height: 68px;
		background: linear-gradient(#6266bf, #112375);
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.msg-icon {
		width: 43px;
		height: 43px;
	}

	.item-data {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-left: 15px;
	}

	.item-msg-title {
		font-weight: 500;
		font-style: normal;
		font-size: 18px;
		color: #333;
	}

	.item-msg-text {
		font-weight: 400;
		font-style: normal;
		color: rgba(51, 51, 51, 0.5);
		font-size: 13px;
	}

	.navbar {
		width: 100%;
		height: 80px;
		background: #fafafa;

			.home-container {
				height: 100%;
				cursor: pointer;
				padding-left: 30px;
				box-sizing: border-box;
				.home-wrapper {
					background: linear-gradient(to bottom, #0AA4F1, #7883FE);
					width: 46px;
					height: 46px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					.home-icon {
						width: 30px;
						height: 30px;
					}
				}
				.username {
					font-size: 30px;
					margin-left: 20px;
				}
			}

			.breadcrumb-container {
				float: left;
			}

			.right-menu {
				height: 100%;

				&:focus {
					outline: none;
				}

				.right-menu-item {
					display: inline-block;
					padding: 0 8px;
					height: 100%;
					font-size: 18px;
					color: #5a5e66;
					vertical-align: text-bottom;

					&.hover-effect {
						cursor: pointer;
						transition: background .3s;

						&:hover {
							background: rgba(0, 0, 0, .025)
						}
					}
				}

				.avatar-container {
					margin-right: 30px;

					.avatar-wrapper {
						position: relative;

						.user-avatar {
							cursor: pointer;
							width: 50px;
							height: 50px;
							border-radius: 50%;
						}

						.el-icon-caret-bottom {
							cursor: pointer;
							position: absolute;
							right: -20px;
							top: 25px;
							font-size: 12px;
						}
					}
				}
				
				.time-container {
					display: flex;
					flex-direction: column;
					margin-right: 30px;
					.time-title {
						font-weight: 650;
						font-style: normal;
						font-size: 45px;
					}
				
					.data-title {
						font-weight: 650;
						font-style: normal;
						font-size: 17px;
					}
				}
			}
	}


	.message-dialog {
		position: fixed;
		width: 400px;
		max-height: 500px;
		z-index: 10000;
		background-color: #fff;
		top: 60px;
		right: 60px;
		border-radius: 20px;
		overflow: hidden;
		overflow-y: auto;

		.message-container {
			.message-title {
				background-color: #437acc;
				height: 80px;
				text-align: center;
				font-size: 20px;
				line-height: 80px;
				font-weight: 800;
				color: #fff;
			}

			.item-icon-view {
				width: 60px;
				height: 60px;
				background-color: #263488;
				border-radius: 10px;
			}
		}
	}
	
	.message-dialog::-webkit-scrollbar {
	  display: none;
	  width: 0 !important;
	}

	::v-deep el-dropdown-menu:hover,
	::v-deep .el-dropdown-menu__item:hover {
		background-color: transparent;
	}
</style>